import './SinglePost.style.scss';
import 'highlight.js/scss/vs.scss';
import { useState } from 'react';
import { qtcreatorLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useParams, useNavigate } from 'react-router-dom';
import { Interweave, Node } from 'interweave';
import SyntaxHighlighter from 'react-syntax-highlighter';
import Spinner from '../spinner/Spinner.component';
//import BlockQuote from '../blockquote/BlockQuote.component';
import Button from '../button/Button.component';
import Modal from '../modal/Modal.component';
import { selectToken } from '../../app/logInOutSlice';
import { selectIsUserLoggedIn } from '../../app/logInOutSlice';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  fetchPost,
  IPost,
  selectPost,
  selectStatus,
  selectError,
} from '../../app/singlePostSlice';
import DisplayTags from '../tag/DisplayTags.component';

import moment from 'moment';
import React, { useEffect } from 'react';

const SinglePost: React.FC = () => {
  let { postId } = useParams<{ postId: any }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = useAppSelector(selectToken);
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchPost(postId));
  }, [postId, dispatch]);

  function transform(node: HTMLElement, children: Node[]): React.ReactNode {
    /* console.log('tagName', node.tagName);
    console.log('className', node.className); */

    /* if (node.tagName === 'BLOCKQUOTE') {
      return <BlockQuote text={children.toString()} />;
    } */
    if (node.tagName === 'IMG') {
      node.className = 'mojaSlika';
      console.log(node);
    }
    if (node.className === 'ql-syntax') {
      return (
        <SyntaxHighlighter
          customStyle={{ backgroundColor: '#f5f5f5', borderRadius: '.3rem' }}
          style={qtcreatorLight}
          language='cpp'
        >
          {children.toString()}
        </SyntaxHighlighter>
      );
    }
  }

  const editButtonHandler = () => {
    navigate(`/update/${post?._id}`);
  };
  const deleteButtonHandler = async () => {
    await fetch(`https://alemosmic.onrender.com/posts/delete/${postId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${token}`,
      },
    }).catch((error) => {
      console.log(error);
      alert(error);
      return;
    });
    navigate('/');
  };

  const post: IPost | undefined = useAppSelector(selectPost);
  const status = useAppSelector(selectStatus);
  const error = useAppSelector(selectError);

  const tagsArr = post?.tags ? post.tags : '';

  let formattedDate = moment(post?.createdAt).format('DD.MM.YYYY');

  let content;

  if (status === 'loading') {
    content = <Spinner />;
  } else if (status === 'succeeded') {
    content = (
      <div className='single-post-wrapper'>
        <div className='content-wrapper'>
          <div className='date-and-button-wrapper'>
            <div className='date'>
              <span>Objavljeno: {formattedDate}.</span>
            </div>
            {isUserLoggedIn && (
              <div className='button-wrapper'>
                <Button text='Edit' onClickHandler={editButtonHandler} />
                <Button
                  text='Delete'
                  onClickHandler={() => setIsModalOpen(true)}
                  style={{
                    backgroundColor: 'red',
                    color: '#fff',
                  }}
                />
              </div>
            )}
          </div>
          <div className='post-title'>{post?.title}</div>
          <Interweave content={post?.description} transform={transform} />
          <hr />
          <DisplayTags tagStr={tagsArr} />
        </div>
      </div>
    );
  } else if (status === 'failed') {
    content = <div className='error'>{error}</div>;
  }

  return (
    <>
      {content}
      {isModalOpen ? (
        <Modal
          heading='Delete Post'
          content='Are you sure you want to delete this post?'
          setIsOpen={setIsModalOpen}
          onOkClick={deleteButtonHandler}
        />
      ) : null}
    </>
  );
};

export default SinglePost;
