import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar.component';
import PostsList from './components/posts-list/PostsList';
import CreatePost from './components/create-post/CreatePost';
import SinglePost from './components/single-post/SinglePost.component';
import Contact from './components/contact/Contact.component';
import Login from './components/login/Login.component';
import Footer from './components/footer/Footer.component';
import UpdatePost from './components/update-post/UpdatePost.component';

function App() {
  return (
    <div className='App'>
      <div className='app-content'>
        <Navbar />
        <Routes>
          <Route path='/' element={<PostsList />} />
          <Route path='/:postId' element={<SinglePost />} />
          <Route path='contact' element={<Contact />} />
          <Route path='login' element={<Login />} />
          <Route path='create-post' element={<CreatePost />} />
          <Route path='update/:postId' element={<UpdatePost />} />
        </Routes>
      </div>
      <div className='footer-component'>
        <Footer />
      </div>
    </div>
  );
}

export default App;
