import { RootState } from './store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPost } from './singlePostSlice';

export interface IStatePosts {
  posts: IPost[];
  status: 'idle' | 'succeeded' | 'failed' | 'loading';
  error: null | string | undefined;
}

const initialState: IStatePosts = {
  posts: [],
  status: 'idle',
  error: null,
};

export const fetchPosts = createAsyncThunk('posts/fetchPosts', async () => {
  try {
    const response = await fetch('https://alemosmic.onrender.com/posts');
    const data = await response.json();
    return data;
  } catch (error: any) {
    console.log('fetching posts error: ', error.message);
  }
});

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.posts = action.payload;
      });
  },
});

export const selectPosts = (state: RootState) => state.posts.posts;
export const selectStatus = (state: RootState) => state.posts.status;
export const selectError = (state: RootState) => state.posts.error;
export const selectPostById = (state: RootState, postId: string) =>
  state.posts.posts.find((post) => post._id === postId);

export default postsSlice.reducer;
