import React from 'react';
import { useEffect } from 'react';
import {
  fetchPosts,
  selectPosts,
  selectStatus,
  selectError,
} from '../../app/postsSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Card from '../card/Card.component';
import Spinner from '../spinner/Spinner.component';

import './PostsList.style.scss';

const PostsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const posts = useAppSelector(selectPosts);
  const status = useAppSelector(selectStatus);
  const error = useAppSelector(selectError);

  useEffect(() => {
    dispatch(fetchPosts());
  }, [dispatch]);

  let content;

  if (status === 'loading') {
    content = <Spinner />;
  } else if (status === 'succeeded') {
    content = (
      <div className='posts-container'>
        {posts.map((post) => {
          return <Card post={post} key={post._id} />;
        })}
      </div>
    );
  } else if (status === 'failed') {
    content = <>{error}</>;
  }

  return <>{content}</>;
};

export default PostsList;
