import './Modal.style.scss';
import Button from '../button/Button.component';

type ModalProp = {
  setIsOpen: (a: boolean) => void;
  heading?: string;
  content?: string;
  onOkClick: () => void;
};

const Modal: React.FC<ModalProp> = ({
  setIsOpen,
  heading = '',
  content = '',
  onOkClick,
}: ModalProp) => {
  return (
    <div className='dark-background' /* onClick={() => setIsOpen(false)} */>
      <div className='centered'>
        <div className='modal'>
          <div className='modal-heading'>
            <h5 className='heading'>{heading}</h5>
          </div>
          <i
            className='bi bi-x-square close-btn'
            onClick={() => setIsOpen(false)}
          ></i>
          <div className='modal-content'>{content}</div>
          <div className='modal-actions'>
            <div className='actions-container'>
              <Button text='OK' onClickHandler={onOkClick} />
              <Button text='Cancel' onClickHandler={() => setIsOpen(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
