import './UpdatePost.style.scss';
import EditPost from '../edit-post/EditPost.component';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { selectToken } from '../../app/logInOutSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { IForm } from '../edit-post/EditPost.component';
import { selectPost, selectStatus, fetchPost } from '../../app/singlePostSlice';

const UpdatePost: React.FC = () => {
  const token = useAppSelector(selectToken);
  const post = useAppSelector(selectPost);
  const postStatus = useAppSelector(selectStatus);

  const { postId } = useParams<{ postId: any }>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [form, setForm] = useState<IForm>({
    title: '',
    description: '',
    tags: '',
    category: 'computer',
  });

  useEffect(() => {
    dispatch(fetchPost(postId));
  }, [dispatch, postId]);

  useEffect(() => {
    setForm({
      title: post.title,
      description: post.description,
      tags: post.tags,
      category: post.category,
    });
  }, [postStatus]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const newPost = { ...form };

    await fetch(`https://alemosmic.onrender.com/posts/update/${postId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${token}`,
      },
      body: JSON.stringify(newPost),
    }).catch((error) => {
      console.log(error);
      return;
    });

    setForm({ title: '', description: '', tags: '', category: '' });
    navigate(`/${postId}`);
  };

  const cancelHandler = () => {
    navigate(`/${postId}`);
  };
  return (
    <div className='update-post-wrapper'>
      <h1>Update Post</h1>
      <EditPost
        form={form}
        setForm={setForm}
        onSubmit={onSubmit}
        onSubmitLabel='Update Post'
        cancelHandler={cancelHandler}
      />
    </div>
  );
};

export default UpdatePost;
