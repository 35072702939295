import { Link } from 'react-router-dom';
import { menuItems } from '../menuItems';
import './Menu.styles.scss';

const Menu: React.FC = () => {
  return (
    <ul className='menus'>
      {menuItems.map((item, i) => (
        <Link className='link' key={i} to={`${item.linkTo}`}>
          {item.title}
        </Link>
      ))}
    </ul>
  );
};

export default Menu;
