import { Link } from 'react-router-dom';
import Menu from './menu/Menu.component';
//import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.styles.scss';
import Search from '../search-box/Search.component';

import {
  resetUserLoggedIn,
  selectIsUserLoggedIn,
  resetToken,
} from '../../app/logInOutSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

const Navbar: React.FC = () => {
  //const [LogBtn, setLogBtn] = useState(window.sessionStorage.getItem('token'));

  //const dispatch = useAppDispatch();
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);

  return (
    <>
      <nav className='navbar'>
        <div className='menu-wrapper'>
          <div className='nav-left-side'>
            <Menu />
            {/* <Search /> */}
          </div>
          <div className='nav-right-side'>
            {isUserLoggedIn && (
              <Link className='navbar-btn' to='create-post'>
                Create Post
              </Link>
            )}
            {isUserLoggedIn ? <LogoutBtn /> : <LoginBtn />}
          </div>
        </div>
      </nav>
    </>
  );
};

const LoginBtn: React.FC = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/login');
  };
  return (
    <div onClick={onClick} className='navbar-btn login-out'>
      Login
    </div>
  );
};
const LogoutBtn: React.FC = () => {
  const dispatch = useAppDispatch();
  const onClick = () => {
    window.sessionStorage.clear();
    dispatch(resetUserLoggedIn());
    dispatch(resetToken());
  };
  return (
    <div onClick={onClick} className='navbar-btn login-out'>
      Logout
    </div>
  );
};

export default Navbar;
