import './Button.style.scss';

import React from 'react';

type ButtonProps = {
  text: string;
  onClickHandler: () => void;
  style?: { color?: string; backgroundColor?: string };
};

const Button: React.FC<ButtonProps> = ({
  text,
  onClickHandler,
  style,
}: ButtonProps) => {
  return (
    <div className='button' onClick={onClickHandler} style={style}>
      {text}
    </div>
  );
};

export default Button;
