import { RootState } from './store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StateLogInOut {
  isUserLoggedIn: Boolean;
  token: string | null;
}

const logInit = () => {
  if (window.sessionStorage.getItem('token')) {
    return true;
  } else {
    return false;
  }
};

const initialState: StateLogInOut = {
  isUserLoggedIn: logInit(),
  token: window.sessionStorage.getItem('token'),
};

export const logInOutSlice = createSlice({
  name: 'logInOut',
  initialState,
  reducers: {
    setUserLoggedIn: (state) => {
      state.isUserLoggedIn = true;
    },
    resetUserLoggedIn: (state) => {
      state.isUserLoggedIn = false;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    resetToken: (state) => {
      state.token = null;
    },
  },
});

export const { setUserLoggedIn, resetUserLoggedIn, setToken, resetToken } =
  logInOutSlice.actions;
export const selectIsUserLoggedIn = (state: RootState) =>
  state.logInOut.isUserLoggedIn;
export const selectToken = (state: RootState) => state.logInOut.token;

export default logInOutSlice.reducer;
