import './DisplayTags.style.scss';

type TagProp = {
  tag: string;
};

type DisplayTagsProps = {
  tagStr: string;
};

const DisplayTags: React.FC<DisplayTagsProps> = ({
  tagStr,
}: DisplayTagsProps) => {
  const tagArr = tagStr.split(' ');
  return (
    <div>
      {tagArr.map((tag, index) => {
        return (
          <span key={index}>
            <Tag tag={tag} />{' '}
          </span>
        );
      })}
    </div>
  );
};

export default DisplayTags;

const Tag: React.FC<TagProp> = ({ tag }: TagProp) => {
  const getClassName = (tag: string) => {
    let tagstr = tag.toLowerCase();
    switch (tagstr) {
      case 'c++':
        return 'cpp';
      case 'javascript':
        return 'javascript';
      case 'html':
        return 'html';
      case 'css':
        return 'css';
      case 'computer':
        return 'computer';
      case 'react':
        return 'react';
      default:
        return 'black';
    }
  };
  return <span className={getClassName(tag)}>#{tag}</span>;
};
