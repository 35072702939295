import './Footer.style.scss';

const Footer: React.FC = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  return (
    <div className='footer-container'>
      Copyright&copy;{currentYear} Alem Osmić All Rights Reserved
    </div>
  );
};

export default Footer;
