import { useState } from 'react';
import './Contact.style.scss';
import profile_image from '../../assets/images/profile_image.jpg';

interface IForm {
  name: string;
  email: string;
  message: string;
}

const Contact: React.FC = () => {
  const [form, setForm] = useState<IForm>({
    name: '',
    email: '',
    message: '',
  });

  const updateForm = (
    value: { name: string } | { email: string } | { message: string }
  ) => {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const newMessage = { ...form };

    await fetch('https://alemosmic.onrender.com/email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newMessage),
    })
      .then((response) => response.json())
      .then((data) => alert(data.message))
      .catch((error) => {
        console.log(error);
        return;
      });

    setForm({ name: '', email: '', message: '' });
  };
  return (
    <div className='section-contact'>
      <h1 className='contact-heading'>Contact</h1>
      <div className='form-container'>
        <div className='img-wrapper'>
          <img src={profile_image} className='profile-img' alt='' />
          <div className='profile-name'>Alem Osmic, dipl.ing.el.</div>
        </div>
        <form className='contact-form' onSubmit={onSubmit}>
          <input
            type='text'
            placeholder='Your Name'
            spellCheck={false}
            value={form.name}
            onChange={(e) => updateForm({ name: e.target.value })}
          />
          <input
            type='text'
            placeholder='Your Email'
            spellCheck={false}
            value={form.email}
            onChange={(e) => updateForm({ email: e.target.value })}
          />
          <textarea
            name='message'
            id='message'
            placeholder='Your message'
            spellCheck={false}
            value={form.message}
            onChange={(e) => updateForm({ message: e.target.value })}
          ></textarea>
          <input type='submit' value='Send' />
        </form>
      </div>
    </div>
  );
};

export default Contact;
