import './Login.style.scss';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/ao-blog-logo.png';

import { setUserLoggedIn, setToken } from '../../app/logInOutSlice';
import { /* useAppSelector, */ useAppDispatch } from '../../app/hooks';

const Login: React.FC = () => {
  const [form, setForm] = useState({ email: '', password: '' });
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  /* const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn); */

  const updateForm = (value: { email: string } | { password: string }) => {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const newLogin = { ...form };

    const response = await fetch('https://alemosmic.onrender.com/users/login', {
      method: 'POST',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify(newLogin),
    });

    if (response.ok) {
      const data = await response.json();

      window.sessionStorage.setItem('token', data.token);
      dispatch(setUserLoggedIn());
      dispatch(setToken(data.token));
      navigate('/');

      /* console.log(data.token); */
    } else {
      const error = await response.json();
      console.log(error.error.message);
    }

    setForm({ email: '', password: '' });
  };
  return (
    <div className='login-wrapper'>
      <div className='form-wrapper'>
        <img src={logo} alt='' />
        <form onSubmit={onSubmit}>
          <input
            type='text'
            name='email'
            placeholder='Enter your email'
            value={form.email}
            onChange={(e) => updateForm({ email: e.target.value })}
          />
          <input
            type='password'
            name='password'
            placeholder='Enter your password'
            value={form.password}
            onChange={(e) => updateForm({ password: e.target.value })}
          />
          <input type='submit' formMethod='post' value='Login' />
        </form>
      </div>
    </div>
  );
};

export default Login;
