import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectToken } from '../../app/logInOutSlice';
import { useAppSelector } from '../../app/hooks';
import EditPost, { IForm } from '../edit-post/EditPost.component';

import './CreatePost.style.scss';

const CreatePost: React.FC = () => {
  const token = useAppSelector(selectToken);

  const navigate = useNavigate();

  const [form, setForm] = useState<IForm>({
    title: '',
    description: '',
    tags: '',
    category: '',
  });

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const newPost = { ...form };

    await fetch('https://alemosmic.onrender.com/posts/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${token}`,
      },
      body: JSON.stringify(newPost),
    }).catch((error) => {
      console.log(error);
      return;
    });

    setForm({ title: '', description: '', tags: '', category: '' });
    navigate('/');
  };

  const cancelHandler = () => {
    navigate('/');
  };

  return (
    <EditPost
      form={form}
      setForm={setForm}
      onSubmit={onSubmit}
      onSubmitLabel='Create Post'
      cancelHandler={cancelHandler}
    />
  );
};

export default CreatePost;
