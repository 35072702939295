import { RootState } from './store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface IPost {
  _id: string;
  title: string;
  description: string;
  tags: string;
  category: string;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
  numViews: number;
  user: any;
  image: string;
}

export interface IStatePost {
  post: IPost;
  status: 'idle' | 'succeeded' | 'failed' | 'loading';
  error: null | string | undefined;
}

const initialState: IStatePost = {
  post: {
    _id: '',
    title: '',
    description: '',
    tags: '',
    category: '',
    createdAt: undefined,
    updatedAt: undefined,
    numViews: 0,
    user: {},
    image: '',
  },
  status: 'idle',
  error: null,
};

export const fetchPost = createAsyncThunk(
  'posts/fetchPost',
  async (id: string) => {
    try {
      const response = await fetch(
        `https://alemosmic.onrender.com/posts/${id}`
      );
      const data = await response.json();
      return data;
    } catch (error: any) {
      console.log('fetching post error: ', error.message);
    }
  }
);

export const singlePostSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchPost.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPost.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPost.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.post = action.payload;
      });
  },
});

export const selectPost = (state: RootState) => state.singlePost.post;
export const selectStatus = (state: RootState) => state.singlePost.status;
export const selectError = (state: RootState) => state.singlePost.error;
export default singlePostSlice.reducer;
