import './EditPost.style.scss';
import { useEffect, useState } from 'react';
/* import ReactQuill from 'react-quill'; */
/* import 'react-quill/dist/quill.snow.css'; */
import QuillEditor from '../quill-editor/QuilEditor.component';

export interface IForm {
  title: string;
  description: string;
  tags: string;
  category: string;
}

type EditPostProp = {
  form: IForm;
  setForm: (a: any) => void;
  onSubmit: (a: any) => void;
  onSubmitLabel?: string;
  cancelHandler?: () => void;
};

const EditPost: React.FC<EditPostProp> = ({
  form,
  setForm,
  onSubmit,
  onSubmitLabel = 'OK',
  cancelHandler,
}: EditPostProp) => {
  const [convertedText, setConvertedText] = useState<string>(
    `${form.description}`
  );

  /* let toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike', 'code'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    //[{ header: 1 }, { header: 2 }], // custom button values
    [{ align: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

    //[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    ['image', 'video', 'link'],
    //[{ direction: 'rtl' }], // text direction

    ['clean'], // remove formatting button
  ]; */

  useEffect(() => {
    setConvertedText(form.description);
  }, [form.description]);

  useEffect(() => {
    updateForm({ description: convertedText });
  }, [convertedText]);

  const updateForm = (
    value:
      | { title: string }
      | { description: string }
      | { tags: string }
      | { category: string }
  ) => {
    return setForm((prev: any) => {
      return { ...prev, ...value };
    });
  };

  return (
    <div className='create-post-wrapper'>
      <form onSubmit={onSubmit}>
        <div className='form-group'>
          <label htmlFor='title'>Title</label>
          <input
            type='text'
            className='form-control'
            spellCheck={false}
            placeholder='Insert title of your post'
            id='title'
            value={form.title}
            onChange={(e) => updateForm({ title: e.target.value })}
          />
        </div>

        <div className='form-group'>
          <QuillEditor
            convertedText={convertedText}
            setConvertedText={setConvertedText}
          />
          {/* <ReactQuill
            className='quill'
            theme='snow'
            modules={{
              toolbar: toolbarOptions,
            }}
            value={convertedText}
            onChange={setConvertedText}
            style={{
              minHeight: '300px',
              width: '100%',
            }}
          /> */}
        </div>
        <div className='form-group'>
          <label htmlFor='category'>Category</label>
          <select
            id='category'
            value={form.category}
            onChange={(e) => updateForm({ category: e.target.value })}
          >
            <option value='computer'>Computer</option>
            <option value='react'>React</option>
            <option value='cpp'>C++</option>
            <option value='html'>HTML</option>
            <option value='css'>CSS</option>
            <option value='javascript'>JavaScript</option>
            <option value='nodejs'>NodeJS</option>
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='tags'>Tags</label>
          <input
            type='text'
            className='form-control'
            spellCheck={false}
            placeholder='Insert tags for this post'
            id='tags'
            value={form.tags}
            onChange={(e) => updateForm({ tags: e.target.value })}
          />
        </div>
        <div className='submit-cancel-btns'>
          <input type='submit' className='submit-btn' value={onSubmitLabel} />
          <button className='cancel' onClick={cancelHandler}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPost;
