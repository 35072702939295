import { IPost } from '../../app/singlePostSlice';
import { NavLink } from 'react-router-dom';
import { Markup } from 'interweave';
import './Card.style.scss';
import DisplayTags from '../tag/DisplayTags.component';
import html_logo from '../../assets/images/html.png';
import css_logo from '../../assets/images/css.png';
import computer_logo from '../../assets/images/computer.png';
import cpp_logo from '../../assets/images/cpp.png';
import js_logo from '../../assets/images/javascript.png';
import react_logo from '../../assets/images/react.png';
import node_logo from '../../assets/images/nodejs.png';

interface CardProps {
  post: IPost;
}

const Card: React.FC<CardProps> = ({ post }: CardProps) => {
  const getImage = (category: string) => {
    const Images = {
      HTML: html_logo,
      CSS: css_logo,
      COMPUTER: computer_logo,
      CPP: cpp_logo,
      JS: js_logo,
      REACT: react_logo,
      NODE: node_logo,
    };
    switch (category) {
      case 'cpp':
        return Images.CPP;
      case 'css':
        return Images.CSS;
      case 'html':
        return Images.HTML;
      case 'javascript':
        return Images.JS;
      case 'react':
        return Images.REACT;
      case 'nodejs':
        return Images.NODE;
      default:
        return Images.COMPUTER;
    }
  };

  return (
    <NavLink className='navlink' to={`/${post._id}`}>
      <div className='card'>
        <img src={getImage(post.category)} alt={post.title} />
        <h2>{post.title}</h2>
        <div className='body'>
          <Markup noHtml={true} content={post.description} />
        </div>
        <div className='tags'>
          <DisplayTags tagStr={post.tags} />
        </div>
      </div>
    </NavLink>
  );
};

export default Card;
