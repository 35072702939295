import { Component } from 'react';
import ReactQuill from 'react-quill';
import { Quill } from 'react-quill';

import './QuillEditor.style.scss';

import 'react-quill/dist/quill.snow.css';

const InlineCodeButton = () => <i className='bi bi-code-square'></i>;
const TerminalButton = () => <i className='bi bi-terminal'></i>;

class QuillEditor extends Component {
  /* constructor(props) {
    super(props);
  } */

  render() {
    return (
      <div className='text-editor'>
        <CustomToolbar />
        <ReactQuill
          theme='snow'
          onChange={this.props.setConvertedText}
          value={this.props.convertedText}
          modules={QuillEditor.modules}
          formats={formats}
        />
      </div>
    );
  }
}

QuillEditor.modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      insertInlineCode: insertInlineCode,
      insertTerminalOutput: insertTerminalOutput,
    },
  },
  clipboard: {
    matchVisual: false,
  },
};

//-------------------------------------
//  CUSTOM TOOLBAR
//-------------------------------------

const CustomToolbar = () => (
  <div id='toolbar'>
    <button className='ql-bold'></button>
    <button className='ql-italic'></button>
    <button className='ql-underline'></button>
    <button className='ql-strike'></button>
    <button className='ql-blockquote'></button>
    <button className='ql-code-block'></button>
    <select className='ql-font' defaultValue={'arial'}>
      <option value='arial'>Arial</option>
      <option value='comic-sans'>Comic Sans</option>
      <option value='courier-new'>Courier New</option>
      <option value='georgia'>Georgia</option>
      <option value='helvetica'>Helvetica</option>
      <option value='lucida'>Lucida</option>
    </select>

    <select className='ql-size'>
      <option value='extra-small'>Size 1</option>
      <option value='small'>Size 2</option>
      <option value='medium'>Size 3</option>
      <option value='large'>Size 4</option>
    </select>

    <select
      className='ql-header'
      defaultValue={''}
      onChange={(e) => e.persist()}
    >
      <option value='1'></option>
      <option value='2'></option>
      <option value='3'></option>
      <option value='4'></option>
      <option value='5'></option>
      <option value='6'></option>
      <option defaultValue={''}></option>
    </select>

    <select className='ql-align' />

    <button className='ql-list' value='ordered'></button>
    <button className='ql-list' value='bullet'></button>
    <button className='ql-indent' value='+1'></button>
    <button className='ql-indent' value='-1'></button>
    <button className='ql-script' value='super'></button>
    <button className='ql-script' value='sub'></button>

    <select className='ql-color'>
      <option value='red'></option>
      <option value='green'></option>
      <option value='blue'></option>
      <option value='orange'></option>
      <option value='violet'></option>
      <option value='#d0d1d2'></option>
      <option defaultValue={''}></option>
    </select>

    <select className='ql-background'>
      <option value='red'></option>
      <option value='green'></option>
      <option value='blue'></option>
      <option value='orange'></option>
      <option value='violet'></option>
      <option value='#d0d1d2'></option>
      <option defaultValue={''}></option>
    </select>

    <button className='ql-insertInlineCode'>
      <InlineCodeButton />
    </button>
    <button className='ql-terminal-output'>
      <TerminalButton />
    </button>
    <button className='ql-image'></button>
    <button className='ql-video'></button>
    <button className='ql-link'></button>
    {/* <button className='ql-formula'></button> */}

    <button className='ql-clean' />
  </div>
);

const Font = Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
];
Quill.register(Font, true);

const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

//-------------------------------------
//  END OF CUSTOM TOOLBAR
//-------------------------------------

//-------------------------------------------------------
//  INLINE CODE
//-------------------------------------------------------
let Inline = Quill.import('blots/inline');

class InlineCode extends Inline {
  static create(value) {
    let node = super.create(value);
    node.setAttribute('class', 'inline-code');
    return node;
  }
}

InlineCode.blotName = 'inline-code';
InlineCode.tagName = 'code';
Quill.register(InlineCode, true);

function insertInlineCode() {
  var range = this.quill.getSelection();
  if (range) {
    this.quill.formatText(range, 'inline-code', 'user');
  } else {
    console.log('it is invalid');
  }
}

//-------------------------------------------------------
//  END OF INLINE CODE
//-------------------------------------------------------
//-------------------------------------------------------
//  TERMINAL OUTPUT
//-------------------------------------------------------
let Block = Quill.import('blots/block');

class TerminalOutput extends Block {
  static create(value) {
    let node = super.create(value);
    node.setAttribute('class', 'terminal-output');
    return node;
  }
}

TerminalOutput.blotName = 'terminal-output';
TerminalOutput.tagName = 'div';
Quill.register(TerminalOutput, true);

function insertTerminalOutput() {
  var range = this.quill.getSelection();
  if (range) {
    this.quill.formatText(range, 'terminal-output', 'user');
  } else {
    console.log('it is invalid');
  }
}

//-------------------------------------------------------
//  END OF TERMINAL OUTPUT
//-------------------------------------------------------

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
  'inline-code',
  'terminal-output',
];

export default QuillEditor;
